import invariant from 'invariant';
import 'prop-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import { Map, List, isImmutable } from 'immutable';
import _isNumber from 'lodash/isNumber';
import _isFinite from 'lodash/isFinite';
import _isString from 'lodash/isString';
import _isObject from 'lodash/isObject';
import _isPlainObject from 'lodash/isPlainObject';
import throwInServerSideRendering from '@dbh/throw-in-server-side-rendering';
import { valuesSpacing } from '@dbh/theme-values';

/**
 * Make a validator that is also executed on `www.daybreakhotels.com`. Use this
 * only when `fnValidate` is "fast", otherwise you can slow down the website.
 * @param {Function} fnValidate Must return `true` if its argument is valid, or false.
 * @param {string?} expectedType The expected "type", it is used only in the error
 * messages. @example `Immutable.Map`.
 * @return {Function} Calls `fnValidate` with its first argument `parameterToValidate`
 * and throws if `fnValidate` returns false. Its second and third argument are
 * only used in the `invariant` error messages (when `fnValidate` returns false).
 */const createValidator=withConformsTo("createValidator",[],(a,b)=>withConformsTo("validator created by createValidator",[],(c,d,e,f)=>{const g=d||"the given parameter";return invariant(a(c,g,e),(e?"(`"+e+"`) ":"")+"Expected `"+g+"` to be a valid: `"+b+"`, instead "+("given: `"+("object"==typeof c?c:c)+"`."+(f?"\n"+f:""))),!0}));var createValidator$1 = createValidator;

const validateImmutableMap=createValidator$1(Map.isMap,"Immutable.Map");var validateImmutableMap$1 = validateImmutableMap;

const validateImmutableListOrArray=createValidator$1(a=>List.isList(a)||Array.isArray(a),"Immutable.List|Array");var validateImmutableListOrArray$1 = validateImmutableListOrArray;

/**
 * Raises an exception if the first argument is not an `Array`.
 * @param {any} maybeNumber Input to be validated.
 * @throws If input is not valid.
 */const validateNumber=createValidator$1(_isNumber,"Number");var validateNumber$1 = validateNumber;

/**
 * Raises an exception if the first argument is not a finite number,
 * a number that is not `NaN`, `+Infinity` or `-Infinity`.
 * @param {any} maybeFiniteNumber Input to be validated.
 * @throws If input is not valid.
 */const validateFiniteNumber=createValidator$1(_isFinite,"Finite number(not NaN, +Inifinity or -Infinity)");var validateFiniteNumber$1 = validateFiniteNumber;

/**
 * Raises an exception if the first argument is not a positive number or `0`,
 * a number that is not `NaN` or `-Infinity`.
 * @param {any} number Input to be validated.
 * @throws If input is not valid.
 */const validateNonNegativeNumber=createValidator$1(a=>_isNumber(a)&&0<=a,"A number `>= 0`.");var validateNonNegativeNumber$1 = validateNonNegativeNumber;

const validateString=createValidator$1(_isString,"String");var validateString$1 = validateString;

const validateNonBlankString=createValidator$1(a=>!!_isString(a)&&!!a.trim(),"non blank String");var validateNonBlankString$1 = validateNonBlankString;

const validateFunction=createValidator$1(a=>a&&"function"==typeof a,"Function");var validateFunction$1 = validateFunction;

const validateTruthyDoNotUseForNumbers=createValidator$1(a=>!!a,"\"truthy\" value");var validateTruthyDoNotUseForNumbers$1 = validateTruthyDoNotUseForNumbers;

/**
 * Raises an exception if the first argument is not an `Array`.
 * @param {any} maybeArray Input to be validated.
 * @throws If input is not valid.
 */const validateArray=createValidator$1(Array.isArray,"Array");var validateArray$1 = validateArray;

/**
 * Raises an exception if the first argument is not an `Object`.
 * @param {any} maybeObject Input to be validated.
 * @throws If input is not valid. Also throws for `Immutable` `objects`.
 */const validateObject=createValidator$1(a=>_isObject(a)&&!isImmutable(a),"Object");var validateObject$1 = validateObject;

/**
 * Raises an exception if the first argument is not a "plain" `Object`.
 * @param {any} maybePlainObject Input to be validated.
 * @throws If input is not valid.
 */const validatePlainObject=createValidator$1(_isPlainObject,"plain `Object`");var validatePlainObject$1 = validatePlainObject;

/**
 * Test whether the string is a valid git commit hash.
 * @see {@link https://git-scm.com/book/en/v2/Git-Tools-Revision-Selection}
 * @param {string} maybeCommitHash .
 * @throws If not a valid commit hash.
 */const validateCommitHash=createValidator$1(a=>/^[a-f0-9]{7,40}$/i.test(a),"`GIT` commit hash");var validateCommitHash$1 = validateCommitHash;

/**
 * Returns `true` if `source` is a `Promise`.
 * "The resolve function returns either a new promise resolved with the passed
 * argument, or the argument itself if the argument is a promise produced by
 * this constructor.".
 * @see {@link http://www.ecma-international.org/ecma-262/6.0/#sec-promise.resolve}
 * @param {any} source .
 * @return {boolean} Is `source` a `Promise`.
 */const isPromise=a=>Promise.resolve(a)===a,validatePromise=createValidator$1(a=>isPromise(a),"`Promise`");var validatePromise$1 = validatePromise;

/**
 * Raises an exception if `keyPathToValidate` is not a valid `key path` in `window`.
 * @param {Object|Immutable.Map} collection The target where the `key path` will be checked.
 * @param {string[]} keyPathToValidate Keys that can be traversed in `collection`.
 * @param {string[]} paramName A textual representation of `keyPathToValidate`.
 * @param {string?} functionName The name of the function that is using the validator.
 * @return {boolean} Is the `key path` valid.
 * @throws If the `key path` is invalid.
 */const validateWindowKeyPath=createValidator$1((a,b,c)=>{throwInServerSideRendering("validateWindowKeyPath"),validateArray$1(a,b,c);let d=0,e=window[a[d]];for(;d!==a.length;){if(!e)return !1;d+=1,e=e[a[d]];}return !0},"a valid `key path` in `window`");var validateWindowKeyPath$1 = validateWindowKeyPath;

/**
 * Checks if the given `value` is one of `spacing` values i.e `sp3`, `0`.
 *
 * @param {string|number} value .
 * @return {boolean} .
 */const isValidSpacingValue=a=>0===a||"0"===a||valuesSpacing.includes(a);var isValidSpacingValue$1 = isValidSpacingValue;

/*
 *
 * `isValidEmail`: `@dbh/validation`.
 *
 */// eslint-disable-next-line no-useless-escape
const REGEX_EMAIL_VALIDATION=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,isValidEmail=a=>// We check that `maybeEmail` is a `string` because there are values that
// cannot be converted to string (@example `Object.create(null)`) that
// would raise an exception.
"string"==typeof a&&REGEX_EMAIL_VALIDATION.test(a);/**
 * Returns `true` if the input provided is a valid email.
 * @param {string?} maybeEmail The value to be tested.
 * @return {boolean} `true` if the email is valid.
 */var isValidEmail$1 = isValidEmail;

const flags=["i","g","m","s","u","y"],invalidResult={isValid:!1,regExp:void 0},getRegExpFromString=withConformsTo("getRegExpFromString",[],a=>{// A minimum valid regular expression has at least three characters.
// @example `/./`.
if(!a||3>a.length)return invalidResult;const b=a.startsWith("/"),c=a.endsWith("/"),d=a.split("/").filter(Boolean),e=d.length;if(!e)return invalidResult;const f=e-1,g=1<e?d[f]:void 0,h=!!g&&!c&&flags.some(a=>g.toLowerCase().includes(a)),i=h?d.splice(f):void 0;if(b&&(c||h))try{const a=new RegExp(d.join("/"),i);return {isValid:!0,regExp:a}}catch(a){return invalidResult}return invalidResult});/**
 *
 * @typedef {{
 * isValid: boolean,
 * regExp?: RegExp
 * }} GetRegExpFromStringResult .
 *//**
 * Parses the string, and if it contains a valid regular expression,
 * it returns it as an instance of `RegExp`.
 * @param {string} inputValue .
 * @return {GetRegExpFromStringResult} .
 */var getRegExpFromString$1 = getRegExpFromString;

export { createValidator$1 as createValidator, getRegExpFromString$1 as getRegExpFromString, isValidEmail$1 as isValidEmail, isValidSpacingValue$1 as isValidSpacingValue, validateArray$1 as validateArray, validateCommitHash$1 as validateCommitHash, validateFiniteNumber$1 as validateFiniteNumber, validateFunction$1 as validateFunction, validateImmutableListOrArray$1 as validateImmutableListOrArray, validateImmutableMap$1 as validateImmutableMap, validateNonBlankString$1 as validateNonBlankString, validateNonNegativeNumber$1 as validateNonNegativeNumber, validateNumber$1 as validateNumber, validateObject$1 as validateObject, validatePlainObject$1 as validatePlainObject, validatePromise$1 as validatePromise, validateString$1 as validateString, validateTruthyDoNotUseForNumbers$1 as validateTruthyDoNotUseForNumbers, validateWindowKeyPath$1 as validateWindowKeyPath };
