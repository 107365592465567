import { createSelector } from 'reselect';
import { makeSelectUser, makeSelectIsBusinessUser, makeSelectIsUserSignedIn, selectUserAreaData, LOYALTY_CAMPAIGN_INFO_LOAD_SUCCESS, LOYALTY_CAMPAIGN_INFO_LOAD_FAILED } from '@dbh/user-area-redux';
import { createSimpleSelector } from '@dbh/reselect-extra';
import { makeSelectCurrentWebpageRouteReference, makeSelectWebpagesSubsetOf, makeSelectTemplateCode } from '@dbh/webpages-redux';
import { ADMIN_PAGE_REFERENCES, PAGE_TYPES, ADMINDEV_WEBCONTROLS_REFERENCES } from '@dbh/admindev-constants';
import { Map } from 'immutable';
import { makeSelectCountry } from '@dbh/routing-redux';
import { makeSelectWebcontrolByReference } from '@dbh/webcontrols-redux';
import { select, call, put } from 'redux-saga/effects';
import 'prop-types';
import { handleSagaError } from '@dbh/redux-saga-extra';
import { API_IDS } from '@dbh/api-constants';
import { makeSelectApiUrl } from '@dbh/configuration-redux';
import { request } from '@dbh/request-queue-redux';
import { makeSelectSagaRequestOptions } from '@dbh/saga-request-options';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import 'react-immutable-proptypes';

/**
 * Returns a selector that returns the user loyalty data.
 * @return {Immutable.Map?} .
 */const makeSelectUserLoyaltyData=()=>createSimpleSelector(makeSelectUser(),["loyalty"]);var makeSelectUserLoyaltyData$1 = makeSelectUserLoyaltyData;

/**
 * Returns a selector that returns `true` if the user is a member of the
 * loyalty campaign.
 * @return {boolean} .
 */const makeSelectDoesUserBelongToLoyaltyProgram=()=>createSelector(makeSelectUserLoyaltyData$1(),makeSelectIsBusinessUser(),(a,b)=>!b&&!!a);var makeSelectDoesUserBelongToLoyaltyProgram$1 = makeSelectDoesUserBelongToLoyaltyProgram;

/**
 * Returns a selector that returns `true` if the current page is the loyalty
 * dashboard page.
 * @see {@link https://test.daybreakhotels.com/IT/en-GB/1/loyalty-program/dashboard}
 * @return {boolean} .
 */const makeSelectIsCurrentPageLoyaltyDashboard=()=>createSelector(makeSelectCurrentWebpageRouteReference(),a=>a===ADMIN_PAGE_REFERENCES.LoyaltyProgramLoyaltyUserDashboard);var makeSelectIsCurrentPageLoyaltyDashboard$1 = makeSelectIsCurrentPageLoyaltyDashboard;

const{ContentPage}=PAGE_TYPES,makeSelectIsCurrentPageLoyaltyContentPage=()=>createSelector(makeSelectWebpagesSubsetOf("reference"),makeSelectTemplateCode(),(a,b)=>b===ContentPage&&a===ADMIN_PAGE_REFERENCES.LoyaltyProgramContentPage);/**
 * Returns a selector that returns `true` if the current page is the loyalty
 * content page.
 * @see {@link https://test.daybreakhotels.com/IT/en-GB/1/loyalty-program}
 * @return {boolean} .
 */var makeSelectIsCurrentPageLoyaltyContentPage$1 = makeSelectIsCurrentPageLoyaltyContentPage;

/**
 * Returns a selector that returns non loyalty user banner visibility depends
 * on user logged in state and permission status.
 * @return {Immutable.Map<string, boolean>} .
 */const makeSelectNonLoyaltyUserBannerVisibilityStates=()=>createSelector(makeSelectIsUserSignedIn(),makeSelectIsCurrentPageLoyaltyContentPage$1(),makeSelectIsBusinessUser(),makeSelectDoesUserBelongToLoyaltyProgram$1(),(a,b,c,d)=>c||!b||d?Map({showCityOfInterestCardBanner:!1,showSignInCardBanner:!1}):Map({showCityOfInterestCardBanner:a,showSignInCardBanner:!a}));var makeSelectNonLoyaltyUserBannerVisibilityStates$1 = makeSelectNonLoyaltyUserBannerVisibilityStates;

/**
 * Returns a selector that returns `true` if the current page is the loyalty
 * content page and the user is not signed in.
 * @see {@link https://test.daybreakhotels.com/IT/en-GB/1/loyalty-program}
 * @return {boolean} .
 */const makeSelectIsNonSignedInUserInLoyaltyContentPage=()=>createSelector(makeSelectIsCurrentPageLoyaltyContentPage$1(),makeSelectIsUserSignedIn(),(a,b)=>a&&!b);var makeSelectIsNonSignedInUserInLoyaltyContentPage$1 = makeSelectIsNonSignedInUserInLoyaltyContentPage;

/**
 * Returns a selector that returns `true` if a loyalty campaign is active in
 * the current country.
 * @return {boolean} .
 */const makeSelectIsLoyaltyCampaignActive=()=>createSelector(selectUserAreaData,makeSelectCountry(),(a,b)=>!!a.getIn(["loyaltyCampaignInfo",b]));var makeSelectIsLoyaltyCampaignActive$1 = makeSelectIsLoyaltyCampaignActive;

/**
 * Returns a selector that returns the loyalty levels banners.
 * @see {@link https://test.daybreakhotels.com/IT/en-GB/1/loyalty-program}
 * @return {Immutable.List?} .
 */const makeSelectLoyaltyLevelsBanners=()=>createSelector(makeSelectWebcontrolByReference(ADMINDEV_WEBCONTROLS_REFERENCES.LOYALTY_LEVELS_BANNER),a=>a?a.get("elements"):void 0);var makeSelectLoyaltyLevelsBanners$1 = makeSelectLoyaltyLevelsBanners;

/**
 * Returns a selector that returns `true` if the loyalty campaign data for the
 * current country is loaded.
 * @return {boolean} .
 */const makeSelectIsLoyaltyCampaignLoaded=()=>createSelector(selectUserAreaData,makeSelectCountry(),(a,b)=>a.hasIn(["loyaltyCampaignInfo",b]));var makeSelectIsLoyaltyCampaignLoaded$1 = makeSelectIsLoyaltyCampaignLoaded;

const loyaltyCampaignInfoLoadSuccess=withConformsTo("loyaltyCampaignInfoLoadSuccess",[],a=>{let{isAtLeastOneActive:b,country:c,requestAttributes:d}=a;return {type:LOYALTY_CAMPAIGN_INFO_LOAD_SUCCESS,payload:{isAtLeastOneActive:b,country:c,requestAttributes:d}}});var loyaltyCampaignInfoLoadSuccess$1 = loyaltyCampaignInfoLoadSuccess;

const loyaltyCampaignInfoLoadFailed=withConformsTo("loyaltyCampaignInfoLoadFailed",[],a=>({type:LOYALTY_CAMPAIGN_INFO_LOAD_FAILED,payload:{error:a}}));var loyaltyCampaignInfoLoadFailed$1 = loyaltyCampaignInfoLoadFailed;

const handleLoadLoyaltyCampaignInfoInvoked=withConformsTo("handleLoadLoyaltyCampaignInfoInvoked",[],function*(a){try{const b=yield select(makeSelectIsLoyaltyCampaignLoaded$1());if(b)return;let{payload:{country:c}={}}=a;c||(c=yield select(makeSelectCountry()));const d=yield select(makeSelectApiUrl(API_IDS.LoyaltyCampaignInfo)),e=yield select(makeSelectSagaRequestOptions(API_IDS.LoyaltyCampaignInfo)),f=yield call(request,d,e),{apiResponse:g,requestAttributes:h}=f,i=g["hydra:member"],j=!(!(null!=i)||!i.some(a=>{let{isActive:b}=a;return b}));yield put(loyaltyCampaignInfoLoadSuccess$1({isAtLeastOneActive:j,country:c,requestAttributes:h}));}catch(a){yield put(loyaltyCampaignInfoLoadFailed$1(a)),handleSagaError(a);}});var handleLoadLoyaltyCampaignInfoInvoked$1 = handleLoadLoyaltyCampaignInfoInvoked;

export { handleLoadLoyaltyCampaignInfoInvoked$1 as handleLoadLoyaltyCampaignInfoInvoked, makeSelectDoesUserBelongToLoyaltyProgram$1 as makeSelectDoesUserBelongToLoyaltyProgram, makeSelectIsCurrentPageLoyaltyContentPage$1 as makeSelectIsCurrentPageLoyaltyContentPage, makeSelectIsCurrentPageLoyaltyDashboard$1 as makeSelectIsCurrentPageLoyaltyDashboard, makeSelectIsLoyaltyCampaignActive$1 as makeSelectIsLoyaltyCampaignActive, makeSelectIsLoyaltyCampaignLoaded$1 as makeSelectIsLoyaltyCampaignLoaded, makeSelectIsNonSignedInUserInLoyaltyContentPage$1 as makeSelectIsNonSignedInUserInLoyaltyContentPage, makeSelectLoyaltyLevelsBanners$1 as makeSelectLoyaltyLevelsBanners, makeSelectNonLoyaltyUserBannerVisibilityStates$1 as makeSelectNonLoyaltyUserBannerVisibilityStates, makeSelectUserLoyaltyData$1 as makeSelectUserLoyaltyData };
