import 'prop-types';
import 'react-immutable-proptypes';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';

const REDUX_AND_SAGA_KEY="webcontrols";const LOAD_WEBCONTROLS_INVOKED="@dbh/webcontrols-redux/LOAD_WEBCONTROLS_INVOKED";const LOAD_WEBCONTROLS_SUCCESS="@dbh/webcontrols-redux/LOAD_WEBCONTROLS_SUCCESS";const LOAD_WEBCONTROLS_ERROR="@dbh/webcontrols-redux/LOAD_WEBCONTROLS_ERROR";const LOAD_WEBCONTROLS_FILTERED_SUCCESS="@dbh/webcontrols-redux/LOAD_WEBCONTROLS_FILTERED_SUCCESS";const LOAD_WEBCONTROLS_FILTERED_ERROR="@dbh/webcontrols-redux/LOAD_WEBCONTROLS_FILTERED_ERROR";const webcontrolsDataKeysForSelectors=["elements","headingText","htmlTag"];

/**
 * Dispatched when the `webcontrols` are loaded by the request saga.
 * @param {Object} options The `webcontrols` data.
 * @param {Immutable.Map} options.requestAttributes .
 * @param {Object[]} options.webcontrols .
 * @param {string} options.country .
 * @param {Immutable.Map?} options.userAreaData .
 * @param {string} options.locale .
 * @param {string} options.pathname .
 * @param {boolean?} options.forceFetch .
 * @param {boolean} options.isUserLoaded .
 * @return {Object} .
 */const webcontrolsLoaded=withConformsTo("webcontrolsLoaded",[],a=>{let{requestAttributes:b,webcontrols:c,userAreaData:d,country:e,locale:f,pathname:g,forceFetch:h,isUserLoaded:i}=a;return {type:LOAD_WEBCONTROLS_SUCCESS,payload:{requestAttributes:b,webcontrols:c,userAreaData:d,country:e,locale:f,pathname:g,forceFetch:h,isUserLoaded:i}}});var webcontrolsLoaded$1 = webcontrolsLoaded;

/**
 * Load the `webcontrols`, this event starts the request saga.
 * @param {Object} options .
 * @param {boolean?} options.forceFetch .
 * @return {Object} .
 */const webcontrolsLoadInvoked=withConformsTo("webcontrolsLoadInvoked",[],a=>{let{forceFetch:b}=a;return {type:LOAD_WEBCONTROLS_INVOKED,forceFetch:b}});var webcontrolsLoadInvoked$1 = webcontrolsLoadInvoked;

/**
 * Dispatched to load the `webcontrols` for the current page, making the `API`
 * requests even if the data is already cached in the redux store.
 * @return {Object} .
 */const loadWebcontrolsAndIgnoreCacheInvoked=()=>webcontrolsLoadInvoked$1({forceFetch:!0});var loadWebcontrolsAndIgnoreCacheInvoked$1 = loadWebcontrolsAndIgnoreCacheInvoked;

/**
 * Dispatched when a filtered `SearchCategoriesTree` `webcontrol` (navigation
 * menu in the `admindev` or `admindevpreprod`) is loaded.
 * @param {Object} options .
 * @param {Immutable.Map} options.requestAttributes .
 * @param {Object} options.webcontrol .
 * @param {string} options.country .
 * @param {string} options.locale .
 * @param {string} options.pathname .
 * @param {string} options.webcontrolReference .
 * @param {Object} options.apiParameters .
 * @return {Object} .
 */const loadWebcontrolFilteredSuccess=withConformsTo("loadWebcontrolFilteredSuccess",[],a=>{let{requestAttributes:b,webcontrol:c,country:d,locale:e,pathname:f,webcontrolReference:g,apiParameters:h}=a;return {type:LOAD_WEBCONTROLS_FILTERED_SUCCESS,payload:{requestAttributes:b,webcontrol:c,country:d,locale:e,pathname:f,webcontrolReference:g,apiParameters:h}}});var loadWebcontrolFilteredSuccess$1 = loadWebcontrolFilteredSuccess;

/**
 * Dispatched when loading the filtered webcontrol fails.
 * @param {Object} options .
 * @param {Error} options.error .
 * @param {string} options.webcontrolReference .
 * @return {Object} .
 */const loadWebcontrolFilteredError=a=>{let{error:b,webcontrolReference:c}=a;return {type:LOAD_WEBCONTROLS_FILTERED_ERROR,payload:{error:b,webcontrolReference:c}}};var loadWebcontrolFilteredError$1 = loadWebcontrolFilteredError;

/**
 * Dispatched when loading the webcontrols fails.
 * @param {Error} error .
 * @return {Object} .
 */const webcontrolsLoadingError=withConformsTo("webcontrolsLoadingError",[],a=>({type:LOAD_WEBCONTROLS_ERROR,error:a}));var webcontrolsLoadingError$1 = webcontrolsLoadingError;

export { LOAD_WEBCONTROLS_ERROR, LOAD_WEBCONTROLS_FILTERED_ERROR, LOAD_WEBCONTROLS_FILTERED_SUCCESS, LOAD_WEBCONTROLS_INVOKED, LOAD_WEBCONTROLS_SUCCESS, REDUX_AND_SAGA_KEY, loadWebcontrolFilteredError$1 as loadWebcontrolFilteredError, loadWebcontrolFilteredSuccess$1 as loadWebcontrolFilteredSuccess, loadWebcontrolsAndIgnoreCacheInvoked$1 as loadWebcontrolsAndIgnoreCacheInvoked, webcontrolsDataKeysForSelectors, webcontrolsLoadInvoked$1 as webcontrolsLoadInvoked, webcontrolsLoaded$1 as webcontrolsLoaded, webcontrolsLoadingError$1 as webcontrolsLoadingError };
