import { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import CurrencyContext from '@dbh/currency-context';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import _noop from 'lodash/noop';

/*
 *
 * Constants: `@dbh/use-formatted-price`.
 *
 */const DEFAULT_PRICE_FORMAT_OPTIONS={currencyDisplay:"symbol",currencyStyle:"currency"};

const replaceCurrencySymbolInFormattedPrice=withConformsTo("replaceCurrencySymbolInFormattedPrice",[["formattedPrice",PropTypes.string.isRequired],["currencySymbol",PropTypes.string]],(a,b)=>b?a.replace(/[^0-9^\s^.^,’]+/g,b):a);/**
 * Replaces the currency symbol with the provided one.
 * @param {string} formattedPrice @example `120$`, `120,450.00$`.
 * @param {string} currencySymbol .
 * @return {string} .
 */var replaceCurrencySymbolInFormattedPrice$1 = replaceCurrencySymbolInFormattedPrice;

const currencyStylePropType=_noop;const currencyDisplayPropType=_noop;

const useFormattedPrice=withConformsTo("useFormattedPrice",[],(a,b)=>{const c={...DEFAULT_PRICE_FORMAT_OPTIONS,...(b||null)},d=useContext(CurrencyContext),{formatNumber:e}=useIntl(),{currencyStyle:f,currencyDisplay:g}=c,h=d&&d.get("displayFloatingPointDigits"),i=d&&d.getIn(["currencyData","currencyCode"]),j=d&&d.getIn(["currencyData","currencySymbol"]),k=useMemo(()=>{if(!a||"number"!=typeof a||!i)return "";const b=h?2:0,c=e(a,{style:f,currency:i,currencyDisplay:g,minimumFractionDigits:b,maximumFractionDigits:b});return replaceCurrencySymbolInFormattedPrice$1(c,j)},[e,a,h,f,g,i,j]);return k});

export { DEFAULT_PRICE_FORMAT_OPTIONS, currencyDisplayPropType, currencyStylePropType, useFormattedPrice as default, replaceCurrencySymbolInFormattedPrice$1 as replaceCurrencySymbolInFormattedPrice };
